import React, { useEffect, useState } from 'react';
import GetinTouch from '../GetInTouch';
import GlobalPresence from '../GlobalPresence';
import JoinOurTeam from '../JoinOurTeam';
import Container from '@material-ui/core/Container';
import './ContactUs.scss';
import RequestFormPopup from '../RequestFormPopup';
import { useStaticQuery, graphql } from 'gatsby';

export default function ContactUs({ data, basePath, backgroundImg}) {
 const[available,setAvailable]= useState(false);

 const contactusData = useStaticQuery(graphql`
    query {
      contentfulContactUs {
       pardotFormAvailable
          contentful_id
    }
    }
 `);
  useEffect(() =>{
    if(contactusData){
    const isAvailable = contactusData.contentfulContactUs.pardotFormAvailable;
    console.log(isAvailable)
    setAvailable(isAvailable);
  }
}, [contactusData]);
useEffect(() => {
  const fetchContentfulData = async () => {
    try {
      const response = await fetch(`https://cdn.contentful.com/spaces/2czxzrwels61/environments/CartusDev/entries/7fQpTKk2HDL25D5TNBVSRT?access_token=s0BzuG-qEIDGGWEiij5rzyjbow5pCkH9apywcwQ52qA`);
      const data = await response.json();
      console.log("API Data", data)
      //setContentfulData(data);
    } catch (error) {
      console.error('Error fetching Contentful data:', error);
    }
  };

  fetchContentfulData();

}, []);

 const [regionalAddress, setRegionalAddress] = useState(
    data?.contentfulContactUs?.chipButton?.chipButtons[0]
  );
 
  return (
    <>
      <div className="contactus-main">
        <GetinTouch setRegionalAddress={setRegionalAddress} data={data} basePath={basePath} />
        <GlobalPresence data={data} regionalAddress={regionalAddress} />
        <div className="contactus-joinus">
          <Container maxWidth="xl" className='no-gutter'>
          <JoinOurTeam
            data={data?.contentfulContactUs?.joinUsSection}
            matrixImg={data?.contentfulContactUs?.smallDotMatrixImage}
            basePath={basePath}
          />
          </Container>
          <div>
          {available === true &&
    (<RequestFormPopup data={data?.contentfulContactUs?.pardotForm} images={backgroundImg} />)}:{(<></>)}
          </div>
        </div>
      </div>
    </>
  );
}
